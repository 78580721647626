export const localStorageKeys = {
  TOKEN: 'token',
  PROFILE: 'profile'
};

export const statusNotification = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

export const titleNotification = {
  SUCCESS: 'Thành công',
  INFO: 'Thông tin',
  WARNING: 'Cảnh báo',
  ERROR: 'Lỗi'
};

export const typeUpload = {
  TEAM_LOGO: 'team_logo',
  AVATAR_BLV: 'avatar',
  IMAGE_HIGHLIGHT: 'image_hightlight',
  IMAGE_PROMOTION: 'promotions',
  ICON_FOLLOW: 'icon_follow',
  streamCollectionId: 'f9605a14-729f-443e-b979-93c4e4bf881c'
};

export const statusMatches = [
  {
    status: 0,
    label: 'Sắp diễn ra'
  },
  {
    status: 1,
    label: 'Hiệp 1'
  },
  {
    status: 2,
    label: 'HT'
  },
  {
    status: 3,
    label: 'Hiệp 2'
  },
  {
    status: 4,
    label: 'Bù giờ'
  },
  {
    status: 5,
    label: 'Penalty'
  },
  {
    status: -1,
    label: 'Kết thúc'
  },
  {
    status: -10,
    label: 'Hủy'
  },
  {
    status: -11,
    label: 'TBD'
  },
  {
    status: -12,
    label: 'Chấm dứt'
  },
  {
    status: -13,
    label: 'Gián đoạn'
  },
  {
    status: -14,
    label: 'Hoãn'
  }
];
