import {
  AndroidOutlined,
  DesktopOutlined,
  FundViewOutlined,
  RedditOutlined,
  TeamOutlined,
  TwitterOutlined,
  UserOutlined,
  CrownOutlined,
  YoutubeOutlined,
  LinkOutlined
} from '@ant-design/icons';
import { routes } from 'routers';

export const listMenu = [
  {
    key: routes.home,
    label: 'Tổng quan',
    icon: <FundViewOutlined />
  },
  // {
  //   key: routes.rtmp,
  //   label: 'Quản Lý Luồng Live',
  //   icon: <DesktopOutlined />
  // },
  {
    key: routes.livestreams,
    label: 'Quản Lý Trận đấu',
    icon: <DesktopOutlined />
  },
  // {
  //   key: routes.thesports,
  //   label: 'Quản Lý Thesports',
  //   icon: <VideoCameraOutlined />
  // },
  {
    key: routes.commentator,
    label: 'Quản Lý BLV',
    icon: <RedditOutlined />
  },
  {
    key: routes.highlight,
    label: 'Highlight',
    icon: <YoutubeOutlined />
  },
  {
    key: routes.follows,
    label: 'FollowUs',
    icon: <TwitterOutlined />
  },
  {
    key: routes.promotions,
    label: 'Quản Lý Khuyến Mãi',
    icon: <CrownOutlined />
  },
  {
    key: routes.users,
    label: 'Quản Lý Tài khoản',
    icon: <UserOutlined />
  },
  {
    key: routes.link_register,
    label: 'Link Đăng Ký',
    icon: <LinkOutlined />
  },
  {
    key: routes.roles,
    label: 'Quản Lý Quyền',
    icon: <TeamOutlined />
  },
  {
    key: routes.tokens,
    label: 'Danh Sách Thiết Bị',
    icon: <AndroidOutlined />
  }
];

export const parentRoute = {
  [routes.home]: routes.home,
  [routes.livestreams]: routes.livestreams,
  [routes.thesports]: routes.thesports,
  [routes.commentator]: routes.commentator,
  [routes.highlight]: routes.highlight,
  [routes.follows]: routes.follows,
  [routes.users]: routes.users,
  [routes.roles]: routes.roles,
  [routes.tokens]: routes.tokens,
  [routes.rtmp]: routes.rtmp,
  [routes.promotions]: routes.promotions,
  [routes.link_register]: routes.link_register
};
