import React from 'react';
import BasicHeader from './BasicHeader';
import BasicContent from './BasicContent';
import BasicFooter from './BasicFooter';
import { Layout } from 'antd';

import BasicSider from './BasicSider';

export default function BasicLayout() {
  return (
    <Layout
      style={{
        minHeight: '100vh'
      }}>
      <BasicSider />
      <Layout>
        <BasicHeader />
        <BasicContent />
        <BasicFooter />
      </Layout>
    </Layout>
  );
}
