import AppRoutes from 'AppRoutes';
import AuthenticationRoutes from 'AuthenticationRoutes';
import Loading from 'components/loading';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <AppRoutes />
        <AuthenticationRoutes />
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
