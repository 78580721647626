import BasicLayout from 'components/layouts/basic';
import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom/dist';
import { routes } from 'routers';
import { localStorageKeys } from 'utils/const';

const HomePage = lazy(() => import('pages/home'));
const LivestreamsPage = lazy(() => import('pages/livestreams'));
const ThesportsPage = lazy(() => import('pages/thesports'));
const CommentatorPage = lazy(() => import('pages/commentator'));
const HighLightPage = lazy(() => import('pages/highlight'));
const Follows = lazy(() => import('pages/follows'));
const Users = lazy(() => import('pages/users'));
const Roles = lazy(() => import('pages/roles'));
const Tokens = lazy(() => import('pages/tokens'));
const RTMPPage = lazy(() => import('pages/rtmp'));
const PromotionsPage = lazy(() => import('pages/promotions'));
const LinkRegisterPage = lazy(() => import('pages/link-register'));

const AppRoutes = () => {
  const token = localStorage.getItem(localStorageKeys.TOKEN);

  if (!token) {
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<BasicLayout />}>
        <Route index element={<HomePage />} />
        <Route path={routes.home} element={<HomePage />} />
        <Route path={routes.livestreams} element={<LivestreamsPage />} />
        <Route path={routes.thesports} element={<ThesportsPage />} />
        <Route path={routes.commentator} element={<CommentatorPage />} />
        <Route path={routes.highlight} element={<HighLightPage />} />
        <Route path={routes.highlight} element={<HighLightPage />} />
        <Route path={routes.follows} element={<Follows />} />
        <Route path={routes.users} element={<Users />} />
        <Route path={routes.roles} element={<Roles />} />
        <Route path={routes.tokens} element={<Tokens />} />
        <Route path={routes.rtmp} element={<RTMPPage />} />
        <Route path={routes.promotions} element={<PromotionsPage />} />
        <Route path={routes.link_register} element={<LinkRegisterPage />} />

        <Route path="*" element={<Navigate to={routes.home} replace />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
