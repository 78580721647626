import { Content } from 'antd/es/layout/layout';
import Loading from 'components/loading';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

export default function BasicContent() {
  return (
    <Content
      style={{
        margin: 10
      }}>
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </Content>
  );
}
