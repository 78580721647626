import React from 'react';
import styles from './style.module.scss';
import logo from 'assets/images/logo.png';

export default function Loading() {
  return (
    <div className={styles.boxLoading}>
      <div className={styles.loadingLogo}>
        <img src={logo} alt="luongson" />
      </div>
      <div className={styles.loaderLine}></div>
    </div>
  );
}
