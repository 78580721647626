import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'styles/index.scss';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ConfigProvider prefixCls="luongson" theme={{}}>
    <App />
  </ConfigProvider>
);
