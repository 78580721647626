export const routes = {
  home: '/',
  livestreams: '/livestreams',
  login: '/login',
  thesports: '/thesports',
  commentator: '/commentator',
  highlight: '/highlight',
  follows: '/follows',
  users: '/users',
  roles: '/roles',
  tokens: '/tokens',
  rtmp: '/rtmp',
  promotions: '/promotions',
  link_register: '/link-register'
};
