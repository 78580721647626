import AuthenticationLayout from 'components/layouts/authentication';
import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { routes } from 'routers';
import { localStorageKeys } from 'utils/const';

const SignIn = lazy(() => import('pages/signIn'));

const AuthenticationRoutes = () => {
  const token = localStorage.getItem(localStorageKeys.TOKEN);

  if (!!token) {
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<AuthenticationLayout />}>
        <Route index element={<Navigate to={routes.login} replace />} />
        <Route path={routes.login} element={<SignIn />} />

        <Route path="*" element={<Navigate to={routes.login} replace />} />
      </Route>
    </Routes>
  );
};

export default AuthenticationRoutes;
