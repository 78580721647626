import { Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React, { useState } from 'react';

import logo from 'assets/images/logo.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routers';
import { listMenu, parentRoute } from 'utils/const/menu';
import { localStorageKeys } from 'utils/const';

export default function BasicSider() {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pRoute = parentRoute[location.pathname];
  const infoUser = JSON.parse(localStorage.getItem(localStorageKeys.PROFILE));

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      theme="light">
      {!collapsed && (
        <div style={{ padding: '2px 10px', backgroundColor: '#000000' }}>
          <Link to={routes.home}>
            <img src={logo} alt="" style={{ width: '100%' }} />
          </Link>
        </div>
      )}
      <Menu
        theme="light"
        defaultSelectedKeys={[pRoute]}
        mode="inline"
        items={listMenu.map((item, i) => {
          if (infoUser?.userName === 'admin')
            return {
              ...item,
              onClick: () => navigate(item.key)
            };
          else {
            if (item?.key === routes.home || item?.key === routes.livestreams)
              return {
                ...item,
                onClick: () => navigate(item.key)
              };
            else return null;
          }
        })}
      />
    </Sider>
  );
}
