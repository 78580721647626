import { Button, Col, Dropdown, Row, Typography } from 'antd';
import React from 'react';
import { Avatar, Space } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { routes } from 'routers';
import { localStorageKeys } from 'utils/const';

const _infoUser = localStorage.getItem(localStorageKeys.PROFILE);

export default function BasicHeader() {
  const infoUser = JSON.parse(_infoUser);
  const handleLogout = () => {
    localStorage.removeItem(localStorageKeys.TOKEN);
    localStorage.removeItem(localStorageKeys.PROFILE);
    window.location.replace(routes.login);
  };

  const items = [
    {
      key: '1',
      label: (
        <Button danger onClick={handleLogout} icon={<LogoutOutlined />}>
          Đăng Xuất
        </Button>
      )
    }
  ];

  return (
    <Row>
      <Col
        span={24}
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
        <Dropdown
          menu={{
            items
          }}>
          <Space>
            <Avatar
              style={{
                backgroundColor: '#fde3cf',
                color: '#f56a00'
              }}>
              {infoUser.fullName.charAt(0)}
            </Avatar>
            <Typography>{infoUser.fullName}</Typography>
          </Space>
        </Dropdown>
      </Col>
    </Row>
  );
}
